<script>
import { mapActions } from 'vuex';
import TableView from '@components/elements/TableView';
import ModalUserCreateEdit from '@components/users/ModalUserCreateEdit';
import DeleteModal from '@components/elements/DeleteModal';

export default {
    page: {
        title: 'Users'
    },

    components: {
        ModalUserCreateEdit,
        DeleteModal
    },

    extends: TableView,

    data() {
        return {
            pageTitle: 'Users',
            userToUpdate: null
        };
    },

    computed: {
        additionalComponents() {
            return [
                {
                    name: 'modal-user-create-edit',
                    props: {
                        updatingUser: this.userToUpdate
                    },
                    events: {
                        hide: this.hiddenModalUserCreteEditHandler,
                        'create-user': this.createUserHandler,
                        'update-user': this.updateUserHandler
                    }
                },
                {
                    name: 'delete-modal',
                    props: {
                        title: 'Do you want to delete this user?'
                    },
                    events: {
                        agree: this.deleteUserHandler,
                        disagree: this.clearIdToDelete
                    }
                }
            ];
        },

        labels() {
            return [
                { value: 'email', width: '200px' },
                { value: 'first_name', width: '180px' },
                {
                    value: 'last_name',
                    width: '180px'
                },
                {
                    value: 'phone',
                    width: '200px'
                },
                {
                    value: 'created_at',
                    filter: 'formatDate',
                    width: '200px',
                    type: 'date'
                },
                {
                    value: 'updated_at',
                    filter: 'formatDate',
                    width: '200px',
                    type: 'date'
                }
            ];
        },

        actions() {
            return [
                {
                    icon: 'fas fa-pen mr-1',
                    method: 'showEditModal'
                }
            ];
        }
    },

    methods: {
        ...mapActions({
            getItems: 'users/index',
            createUser: 'users/store',
            updateUser: 'users/update',
            deleteUser: 'users/destroy'
        }),

        hiddenModalUserCreteEditHandler() {
            this.userToUpdate = null;
        },

        async createUserHandler(data) {
            try {
                const user = await this.createUser(data);

                this.items = [user, ...this.items];

                this.$bvToast.toast('User has been added.', {
                    title: 'Success!',
                    variant: 'success',
                    solid: true
                });
            } catch (err) {
                console.error(err);

                const message = err.response.data || 'Something went wrong.';

                this.$bvToast.toast(message, {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        async updateUserHandler(data) {
            try {
                const response = await this.updateUser(data);

                const index = this.items.findIndex(i => data.id === i.id);
                this.items[index] = { ...response };
                this.items = this.items.map(i => ({ ...i }));

                this.$bvToast.toast('User has been updated.', {
                    title: 'Success!',
                    variant: 'success',
                    solid: true
                });
            } catch (err) {
                console.error(err);

                const message = err.response.data || 'Something went wrong.';

                this.$bvToast.toast(message, {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        async deleteUserHandler() {
            try {
                await this.deleteUser(this.idToDelete);
                this.items = this.items.filter(
                    item => item.id !== this.idToDelete
                );
            } catch (err) {
                console.error(err);

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        showDeleteModal(user) {
            this.idToDelete = user.id;
            this.$bvModal.show('confirm-delete-modal');
        },

        onAddButtonClick() {
            this.$bvModal.show('modalUserCreateEdit');
        },

        showEditModal(user) {
            const {
                created_at,
                updated_at,
                acl: { permissions },
                roles,
                ...data
            } = user;

            data.permissions = permissions;

            this.userToUpdate = { ...data, role: roles[0].name };
            this.$bvModal.show('modalUserCreateEdit');
        },

        clearIdToDelete() {
            this.idToDelete = null;
        }
    }
};
</script>
