<template>
    <b-modal
        id="modalUserCreateEdit"
        class="modal-demo"
        title-class="text-light"
        header-bg-variant="dark"
        header-text-variant="light"
        title-tag="h4"
        size="lg"
        centered
        @show="openModalHandler"
        @hide="hideModalHandler"
    >
        <template slot="modal-title">
            {{ modalTitle }}
        </template>
        <div class="custom-modal-text text-left">
            <div>
                <div class="form-group">
                    <label for="role">Role</label>
                    <b-form-select
                        v-model="data.role"
                        :options="roles"
                        :class="{ 'is-invalid': $v.data.role.$error }"
                    />
                </div>
                <div class="form-group">
                    <label for="email">Email</label>
                    <input
                        id="email"
                        v-model="data.email"
                        :class="{ 'is-invalid': $v.data.email.$error }"
                        type="text"
                        class="form-control"
                        placeholder="Enter Email"
                    />
                </div>
                <div class="form-group">
                    <label for="first_name">First Name</label>
                    <input
                        id="first_name"
                        v-model="data.first_name"
                        :class="{ 'is-invalid': $v.data.first_name.$error }"
                        type="text"
                        class="form-control"
                        placeholder="Enter first name"
                    />
                </div>
                <div class="form-group">
                    <label for="last_name">Last Name</label>
                    <input
                        id="last_name"
                        v-model="data.last_name"
                        :class="{ 'is-invalid': $v.data.last_name.$error }"
                        type="text"
                        class="form-control"
                        placeholder="Last Name"
                    />
                </div>
                <div class="form-group">
                    <label for="phone">Phone</label>
                    <input
                        id="phone"
                        v-model="data.phone"
                        type="text"
                        class="form-control"
                        placeholder="Enter phone number"
                    />
                </div>
                <div v-if="!updatingUser" class="form-group">
                    <label for="password">Password</label>
                    <input
                        id="password"
                        v-model="data.password"
                        :class="{ 'is-invalid': $v.data.password.$error }"
                        type="password"
                        class="form-control"
                        placeholder="Enter password"
                    />
                    <span v-if="$v.data.password.$error">
                        Password must be minimum 6 symbols and contain numbers
                        and letters.
                    </span>
                </div>
                <div v-show="data.role === MANAGER_ROLE" class="mt-3">
                    <h5>Permissions</h5>
                    <div class="form-group">
                        <b-form-checkbox
                            v-model="data.permissions.events"
                            switch
                        >
                            Events
                        </b-form-checkbox>
                    </div>
                    <div class="form-group">
                        <multiselect
                            v-model="data.permissions.products"
                            :options="products"
                            label="name"
                            track-by="id"
                            :multiple="true"
                            placeholder="Products"
                        />
                    </div>
                </div>
            </div>
        </div>

        <template #modal-footer>
            <div class="col-12 d-flex justify-content-between">
                <button
                    type="button"
                    class="btn btn-danger waves-effect waves-light mr-2"
                    @click="hideModal"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    class="btn btn-success waves-effect waves-light"
                    @click="submitUserDataHandler"
                >
                    Save
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';

export default {
    components: {
        Multiselect
    },

    props: {
        updatingUser: {
            type: Object,
            required: false,
            default: null
        }
    },

    validations() {
        if (!this.updatingUser) {
            return {
                data: {
                    role: {
                        required
                    },
                    email: {
                        required,
                        email
                    },
                    first_name: {
                        required
                    },
                    last_name: {
                        required
                    },
                    password: {
                        required,
                        minLength: minLength(6),
                        containDigit: v => {
                            return /\d/.test(v);
                        }
                    }
                }
            };
        } else {
            return {
                data: {
                    role: {
                        required
                    },
                    email: {
                        required,
                        email
                    },
                    first_name: {
                        required
                    },
                    last_name: {
                        required
                    }
                }
            };
        }
    },

    data() {
        return {
            data: { permissions: {} },
            modalTitle: 'Add New User',
            roles: [
                { text: 'Admin', value: 'admin' },
                { text: 'Project Manager', value: 'project_manager' }
            ],
            MANAGER_ROLE: 'project_manager',
            products: []
        };
    },

    async mounted() {
        try {
            const products = await this.getProducts();

            this.products = products.map(item => ({
                id: item.id,
                name: item.name
            }));
        } catch (err) {
            console.error(err);

            this.$bvToast.toast('Something went wrong!', {
                title: 'Error!',
                variant: 'danger',
                solid: true
            });
        }
    },

    methods: {
        ...mapActions({
            getProducts: 'products/index'
        }),

        hideModal() {
            this.$bvModal.hide('modalUserCreateEdit');
        },

        async openModalHandler() {
            await this.$nextTick();

            if (this.updatingUser) {
                this.data = { ...this.updatingUser };
                this.modalTitle = 'Edit User';

                return;
            }

            this.data = {
                permissions: {}
            };
        },

        hideModalHandler() {
            this.$v.$reset();
            this.$emit('hide');
        },

        submitUserDataHandler() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            if (this.data.role === this.MANAGER_ROLE) {
                delete this.data.permissions.all;
            }

            this.$emit(
                this.updatingUser ? 'update-user' : 'create-user',
                this.data
            );

            this.$v.$reset();
            this.hideModal();
        }
    }
};
</script>
